// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Categories
export const getCategories = createAsyncThunk('helpers/getCategories', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getCategoriesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      categories: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    isLoadingCategories: false,
    categories: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoadingCategories = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;

        state.isLoadingCategories = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.isLoadingCategories = false;
      });
  },
});

export default helpersSlice.reducer;
