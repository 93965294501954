// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Diplomas
export const getDiplomas = createAsyncThunk('diplomas/getDiplomas', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getDiplomasEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      diplomas: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Diploma
export const createDiploma = createAsyncThunk('diplomas/createDiploma', async (params, { rejectWithValue }) => {
  try {
    const response = await http.post(apiConfig.createDiplomaEndpoint, {
      ...params,
    });
    const data = response.data;

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Diploma
export const editDiploma = createAsyncThunk('diplomas/editDiploma', async (params, { rejectWithValue }) => {
  try {
    const response = await http.patch(apiConfig.editDiplomaEndpoint.replace(':id', params.id), params);
    const data = response.data;

    return {
      diploma: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Diploma
export const deleteDiploma = createAsyncThunk('diplomas/deleteDiploma', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.delete(apiConfig.deleteDiplomaEndpoint.replace(':id', params.id));
    const data = response.data;

    dispatch(getDiplomas());

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Diploma
export const getDiploma = createAsyncThunk('diplomas/getDiploma', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getDiplomaEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      diploma: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Upload Photo
export const uploadPhoto = createAsyncThunk('diplomas/uploadPhoto', async (params, { rejectWithValue }) => {
  try {
    const fd = new FormData();
    fd.append('file', params.file);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const response = await http.post(apiConfig.photoUploadEndpoint, fd, config);
    const data = response.data;

    return {
      file: data.file || null,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const diplomasSlice = createSlice({
  name: 'diplomas',
  initialState: {
    isLoadingDiplomas: false,
    isLoadingDiploma: false,
    diplomas: [],
    diploma: {},
    diplomasError: false,
    diplomaError: false,
  },
  reducers: {
    resetDiploma: (state) => {
      state.isLoadingDiploma = false;
      state.diplomaError = false;
      state.diploma = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiplomas.pending, (state) => {
        state.isLoadingDiplomas = true;
        state.diplomasError = false;
      })
      .addCase(getDiplomas.fulfilled, (state, action) => {
        state.diplomas = action.payload.diplomas;

        state.isLoadingDiplomas = false;
      })
      .addCase(getDiplomas.rejected, (state, action) => {
        state.isLoadingDiplomas = false;

        state.diplomasError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getDiploma.pending, (state) => {
        state.isLoadingDiploma = true;

        state.diplomasError = false;
      })
      .addCase(getDiploma.fulfilled, (state, action) => {
        state.diploma = action.payload.diploma;

        state.isLoadingDiploma = false;
      })
      .addCase(getDiploma.rejected, (state, action) => {
        state.isLoadingDiploma = false;

        state.diplomaError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export const { resetDiploma } = diplomasSlice.actions;

export default diplomasSlice.reducer;
