export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.media5.com/',
  getSolutionsEndpoint: '/pages/',
  getSolutionEndpoint: '/pages/:id/',
  editSolutionEndpoint: '/pages/:id/',
  deleteSolutionEndpoint: '/pages/:id/',
  createSolutionEndpoint: '/pages/',
  getSolutionHistoryEndpoint: '/pages/:id/history/',
  getDiplomasEndpoint: '/diplomas/',
  getDiplomaEndpoint: '/diplomas/:id/',
  editDiplomaEndpoint: '/diplomas/:id/',
  deleteDiplomaEndpoint: '/diplomas/:id/',
  createDiplomaEndpoint: '/diplomas/',
  getCategoriesEndpoint: '/categories/',
  getCategoryEndpoint: '/categories/:id/',
  editCategoryEndpoint: '/categories/:id/',
  deleteCategoryEndpoint: '/categories/:id/',
  createCategoryEndpoint: '/categories/',
  photoUploadEndpoint: '/documents/upload/',
};
