// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Categories
export const getCategories = createAsyncThunk('categories/getCategories', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getCategoriesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      categories: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Create Category
export const createCategory = createAsyncThunk('categories/createCategory', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.post(apiConfig.createCategoryEndpoint, {
      ...params,
    });
    const data = response.data;

    dispatch(getCategories());

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Edit Category
export const editCategory = createAsyncThunk('categories/editCategory', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.patch(apiConfig.editCategoryEndpoint.replace(':id', params.id), params);
    const data = response.data;

    dispatch(getCategories());

    return {
      category: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Delete Category
export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await http.delete(apiConfig.deleteCategoryEndpoint.replace(':id', params.id));
    const data = response.data;

    dispatch(getCategories());

    return {
      data: data,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Category
export const getCategory = createAsyncThunk('categories/getCategory', async (params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getCategoryEndpoint.replace(':id', params.id));
    const data = response.data;

    return {
      category: data || {},
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    isLoadingCategories: false,
    isLoadingCategory: false,
    categories: [],
    category: {},
    categoriesError: false,
    categoryError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoadingCategories = true;
        state.categoriesError = false;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;

        state.isLoadingCategories = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoadingCategories = false;

        state.categoriesError = action.payload?.detail || 'Произошла ошибка';
      });
    builder
      .addCase(getCategory.pending, (state) => {
        state.isLoadingCategory = true;

        state.categoryError = false;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.category = action.payload.category;

        state.isLoadingCategory = false;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.isLoadingCategory = false;

        state.categoryError = action.payload?.detail || 'Произошла ошибка';
      });
  },
});

export default categoriesSlice.reducer;
